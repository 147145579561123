import React from 'react'
import SEO from '../components/seo/seo'
import Disclaimer from '../components/disclaimer/disclaimer'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const ProbateAdministrationPage = () => (
  <>
    <SEO 
      title="Services" 
      description="Probate Lawyer Jim Feleen offers probate administration in matters such as wills and estates. 603-504-6507."
      keywords={['lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'probate', 'probate administration', 'estate', 'wills']} />
    <Banner />
    <div className="body-wrapper"> 
    <h2>Probate Administration</h2>
      <p>
        The death or incapacity of a loved one is a traumatic, life changing event. The involvement of a skilled and experienced attorney is critical in these times of family stress.
      </p>
      <p>
        In New Hampshire, the administration of a person’s affairs after his or her death is a court proceeding overseen by a probate court judge. Depending on the size of the estate, the type of assets and other factors, the probate administration process can take up to a year or more to complete.
      </p>
      <p>
        Each step of probate administration involves preparing and filing the appropriate court forms. A surety bond is often required. Gathering and valuation of assets and identifying creditors is a critical step. Our law office is equipped with the knowledge and experience to guide our clients through the probate administration process as quickly and efficiently as possible.
      </p>
      <p>
        <Link to="/contact/">Contact</Link> Attorney Jim Feleen today for a free probate administration consultation.
      </p>
            
    </div>
    <Disclaimer />
  </>
)

const Banner = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="body-wrapper">
        
        <Img alt="Legal document and a pen" className="hero-image" fluid={data.file.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "contract-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ProbateAdministrationPage
